export const setWidth = props => {
	if (props.width) {
		return `width:${props.width}px;`;
	}
};

export const setOverflowHiddenOnBody = () => {
	document.getElementsByTagName("body")[0].style.overflow = "hidden";
};

export const unsetOverflowHiddenOnBody = () => {
	document.getElementsByTagName("body")[0].style.overflow = "";
};

export const breakPoints = { subscriptionPage: "800px" };
