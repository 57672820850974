import miniLessonsActionTypes from "./mini-lessons.types";

export const getMiniLessonsIDsIndexActionStart = () => ({
	type: miniLessonsActionTypes.GET_MINI_LESSONS_IDS_INDEX_START,
});

export const getMiniLessonsIDsIndexActionSuccess = payload => ({
	type: miniLessonsActionTypes.GET_MINI_LESSONS_IDS_INDEX_SUCCESS,
	payload,
});

export const getMiniLessonsIDsIndexActionFailure = () => ({
	type: miniLessonsActionTypes.GET_MINI_LESSONS_IDS_INDEX_FAILURE,
});

export const chooseRandomMiniLessonActionStart = () => ({
	type: miniLessonsActionTypes.CHOOSE_RANDOM_MINI_LESSON_START,
});

export const chooseRandomMiniLessonActionFailure = () => ({
	type: miniLessonsActionTypes.CHOOSE_RANDOM_MINI_LESSON_FAILURE,
});

export const chooseRandomMiniLessonActionSuccess = payload => ({
	type: miniLessonsActionTypes.CHOOSE_RANDOM_MINI_LESSON_SUCCESS,
	payload,
});

export const getNewsetMiniLessonStart = () => ({
	type: miniLessonsActionTypes.GET_NEWEST_MINI_LESSON_START,
});

export const getNewsetMiniLessonFailure = () => ({
	type: miniLessonsActionTypes.GET_NEWEST_MINI_LESSON_FAILURE,
});

export const getNewsetMiniLessonSuccess = payload => ({
	type: miniLessonsActionTypes.GET_NEWEST_MINI_LESSON_SUCCESS,
	payload,
});

export const getMiniLessonStart = payload => ({
	type: miniLessonsActionTypes.GET_MINI_LESSON_START,
	payload,
});

export const getMiniLessonFailure = () => ({
	type: miniLessonsActionTypes.GET_MINI_LESSON_FAILURE,
});

export const getMiniLessonSuccess = payload => ({
	type: miniLessonsActionTypes.GET_MINI_LESSON_SUCCESS,
	payload,
});
