import React from "react";
import { connect } from "react-redux";

import Background from "../dark-background/dark-background.styles";
import {
	selectIsActiveSignUpFlow,
	selectIsLoggedIn,
	selectSignUpFlowCompletionHandlers,
} from "../../redux/current-user/current-user.selector";
import { useEffect } from "react";
import SignInUp from "../sign-in-up/sign-in-up.component";
import { endSignUpFlow } from "../../redux/current-user/current-user.actions";
import {
	setOverflowHiddenOnBody,
	unsetOverflowHiddenOnBody,
} from "../../utils/css.utils";

const SignUpOverlay = ({
	isActive,
	dispatch,
	isLoggedIn,
	signUpFlowCompletionHandlers,
	hideSignUpFlow,
}) => {
	useEffect(() => {
		//event is triggered them the histore object changes. This means that it's triggered when someone the user changes urls.
		window.addEventListener("popstate", () => {
			if (isActive) {
				hideSignUpFlow();
			}
		});
		if (isActive) {
			document.getElementById("App").style.maxHeight = "100vh";
			document.getElementById("App").style.overflow = "hidden";
			setOverflowHiddenOnBody();
		} else {
			document.getElementById("App").style.maxHeight = "";
			document.getElementById("App").style.overflow = "none";
			unsetOverflowHiddenOnBody();
		}
	});
	useEffect(() => {
		if (isLoggedIn) {
			hideSignUpFlow();
		}
		completionHandlerFunc();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn]);

	const completionHandlerFunc = () => {
		signUpFlowCompletionHandlers.forEach(item => {
			dispatch({ type: item.action, payload: item.payload });
		});
	};

	if (isActive)
		return (
			<Background zIndex={910} id="BackgroundOfSignUpOverlady">
				<SignInUp />
			</Background>
		);
	else {
		return null;
	}
};

const mapStateToProps = state => ({
	isActive: selectIsActiveSignUpFlow(state),
	isLoggedIn: selectIsLoggedIn(state),
	signUpFlowCompletionHandlers: selectSignUpFlowCompletionHandlers(state),
});

const mapDispatchToState = dispatch => ({
	dispatch: dispatch,
	hideSignUpFlow: () => {
		dispatch(endSignUpFlow());
	},
});

export default connect(mapStateToProps, mapDispatchToState)(SignUpOverlay);
