import React from "react";

import { Container, Title } from "./page-with-text.styles";

const PageWithText = ({ children }) => {
	return (
		<Container>
			<Title>{children}</Title>
		</Container>
	);
};

export default PageWithText;
