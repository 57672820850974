import React, { useEffect, useState } from "react";

import {
	Divider,
	HorizontalContainer,
	Hour,
	VerticalContainer,
} from "./calendar.styles";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase/firebase.utils";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedPrivateLesson } from "../../redux/private-lessons/private-lessons.selectors";
import { setSelectedPrivateLessonAction } from "../../redux/private-lessons/private-lessons.actions";
import { selectUID } from "../../redux/current-user/current-user.selector";
import { showAlertAction } from "../../redux/alert/alert.actions";

const Calendar = () => {
	const [privateLessons, setPrivateLessons] = useState({});
	const [metadata, setMetadata] = useState({});
	const uid = useSelector(selectUID);
	const [days, setDays] = useState([]);
	const meridiems = ["AM", "PM"];
	// const days = ["MON", "TUE", "WED", "THU", "FRI"];
	const selected = useSelector(selectSelectedPrivateLesson);
	const dispatch = useDispatch();
	async function getData() {
		try {
			const privateLessonRef = collection(firestore, "PrivateLessons");
			const snap = await getDocs(privateLessonRef);

			const privateLessonsForState = {};
			snap.forEach(doc => {
				const data = doc.data();
				const id = doc.id;
				data["id"] = id;

				if (id === "PrivateLessonsMetadata") {
					setMetadata(data);
				} else {
					if (data.StudentUID === uid)
						dispatch(setSelectedPrivateLessonAction(data));
					else if (data.StudentUID) return;
					privateLessonsForState[data.Day] = {
						...privateLessonsForState[data.Day],
						[data.Meridiem]: {
							...(privateLessonsForState[data.Day]
								? privateLessonsForState[data.Day][
										data.Meridiem
								  ]
								: {}),
							[data.Hour]: {},
						},
					};
					privateLessonsForState[data.Day][data.Meridiem][data.Hour] =
						{ ...data };
				}
			});
			console.log(privateLessonsForState);
			setPrivateLessons(privateLessonsForState);
			setDays(["MON", "TUE", "WED", "THU", "FRI"]);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (metadata.MaxAmountOfLessons <= metadata.AmountOfLessons) {
			dispatch(
				showAlertAction({
					title: "Oops.",
					description:
						"There are no available private lessons. Please check back in one month or consider subscribing to the VIP plan.",
				})
			);
		}
	}, [metadata]);

	return (
		<HorizontalContainer>
			{days.length === 0 ? "please wait ..." : null}
			{days.map(day => {
				return (
					<HorizontalContainer style={{ margin: "unset" }} key={day}>
						<VerticalContainer>
							<p>{day}</p>
							{privateLessons[day]
								? meridiems.map(meridiem => {
										if (privateLessons[day][meridiem])
											return Object.keys(
												privateLessons[day][meridiem]
											).map(hour => (
												<Hour
													key={day + hour + meridiem}
													onClick={() => {
														dispatch(
															setSelectedPrivateLessonAction(
																privateLessons[
																	day
																][meridiem][
																	hour
																]
															)
														);
													}}
													selected={
														selected &&
														selected.id ===
															privateLessons[day][
																meridiem
															][hour].id
													}
												>
													{hour} {meridiem}
												</Hour>
											));
										return null;
								  })
								: null}
						</VerticalContainer>
						{day !== "FRI" ? <Divider /> : null}
					</HorizontalContainer>
				);
			})}
		</HorizontalContainer>
	);
};

export default Calendar;
