const miniLessonsActionTypes = {
	GET_MINI_LESSONS_IDS_INDEX_START: "GET_MINI_LESSONS_IDS_INDEX_START",
	GET_MINI_LESSONS_IDS_INDEX_FAILURE: "GET_MINI_LESSONS_IDS_INDEX_FAILURE",
	GET_MINI_LESSONS_IDS_INDEX_SUCCESS: "GET_MINI_LESSONS_IDS_INDEX_SUCCESS",
	CHOOSE_RANDOM_MINI_LESSON_START: "CHOOSE_RANDOM_MINI_LESSON_START",
	CHOOSE_RANDOM_MINI_LESSON_FAILURE: "CHOOSE_RANDOM_MINI_LESSON_FAILURE",
	CHOOSE_RANDOM_MINI_LESSON_SUCCESS: "CHOOSE_RANDOM_MINI_LESSON_SUCCESS",
	GET_NEWEST_MINI_LESSON_START: "GET_NEWEST_MINI_LESSON_START",
	GET_NEWEST_MINI_LESSON_FAILURE: "GET_NEWEST_MINI_LESSON_FAILURE",
	GET_NEWEST_MINI_LESSON_SUCCESS: "GET_NEWEST_MINI_LESSON_SUCCESS",
	GET_MINI_LESSON_START: "GET_MINI_LESSON_START",
	GET_MINI_LESSON_FAILURE: "GET_MINI_LESSON_FAILURE",
	GET_MINI_LESSON_SUCCESS: "GET_MINI_LESSON_SUCCESS",
};

export default miniLessonsActionTypes;
