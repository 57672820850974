import styled from "styled-components";

export const shortPromotionVideoWidth = "300px";

export const Container = styled.div`
	width: ${shortPromotionVideoWidth};
	padding: 10px;
	padding-bottom: 0px;
	min-width: 300px;

	@media only screen and (min-width: 800px) {
		grid-row-start: 1;
		grid-row-end: end;
	}
`;
export const ButtonContainer = styled.div`
	height: 50px;
	width: 100%;
	display: flex;
	justify-content: center;
	//align-items: center;
	gap: 20px;
`;

export const TextButtonContainer = styled.div`
	top: 20px;
	padding-top: 18px;
`;
