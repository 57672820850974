import React from "react";
import { connect } from "react-redux";
import "./loading-animation.styles.css";
import { selectIsLoading } from "../../redux/loading-animation/loading-animation.selectors";

const LoadingAnimation = ({
	isLoading,
	children,
	overrideIsLoading = undefined,
	style,
}) => {
	//use isLoading only if overrideIsLoading is undefined
	const isLoadingLocal =
		overrideIsLoading || (overrideIsLoading === undefined && isLoading);
	if (isLoadingLocal) {
		return (
			<div className="loading-animation-container" {...{ style }}>
				<div className="lds-ellipsis">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	} else {
		return children ? children : <></>;
	}
};

const mapStateToProps = state => ({
	isLoading: selectIsLoading(state),
});

export default connect(mapStateToProps)(LoadingAnimation);
