import subscriptionActionTypes from "./subscription.types";

export const getOffersStart = () => ({
	type: subscriptionActionTypes.GET_OFFERS_START,
});

export const getOffersFailure = () => ({
	type: subscriptionActionTypes.GET_OFFERS_FAILURE,
});

export const getOffersSuccess = offers => ({
	type: subscriptionActionTypes.GET_OFFERS_SUCCESS,
	payload: offers,
});

export const redirectToCheckoutStart = payload => ({
	type: subscriptionActionTypes.REDIRECT_TO_CHECKOUT_START,
	payload: payload,
});

export const redirectToCheckoutSuccess = () => ({
	type: subscriptionActionTypes.REDIRECT_TO_CHECKOUT_SUCCESS,
});

export const redirectToCheckoutFailure = () => ({
	type: subscriptionActionTypes.REDIRECT_TO_CHECKOUT_FAILURE,
});

export const setPackagesFromParentApp = payload => ({
	type: subscriptionActionTypes.SET_PACKAGES_FROM_PARENT_APP,
	payload,
});

export const cancelSubscriptionStart = () => ({
	type: subscriptionActionTypes.CANCEL_SUBSCRIPTION_START,
});

export const cancelSubscriptionFailure = payload => ({
	type: subscriptionActionTypes.CANCEL_SUBSCRIPTION_FAILURE,
	payload,
});

export const cancelSuscriptionSuccess = () => ({
	type: subscriptionActionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
});

export const cancelSubscriptionConfirmed = () => ({
	type: subscriptionActionTypes.CANCEL_SUBSCRIPTION_CONFIRMATION,
});

export const checkPrerequisitsStart = payload => ({
	type: subscriptionActionTypes.CHECK_PREREQUISITS_START,
	payload,
});

export const checkPrerequisitisSuccess = payload => ({
	type: subscriptionActionTypes.CHECK_PREREQUISITS_SUCCESS,
	payload,
});
