import currentUserActionTypes from "./current-user.types";

const INITIAL_STATE = {
	isLoggedIn: false,
	signUpFlow: { isActive: false, completionHandlers: [] },
	uploadConsentsWhenAuthenticatedSuccessfully: false,
};

const currentUserReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case currentUserActionTypes.ADD_USER:
			if (action.payload) {
				return { ...state, isLoggedIn: true, ...action.payload };
			}
			return { ...state, isLoggedIn: true };
		case currentUserActionTypes.REMOVE_USER:
			return {
				...state,
				isLoggedIn: false,
				UID: "",
				Username: "",
				email: "",
			};
		case currentUserActionTypes.START_SIGN_UP_FLOW:
			return {
				...state,
				signUpFlow: {
					isActive: true,
					completionHandlers: action.payload,
				},
			};
		case currentUserActionTypes.END_SIGN_UP_FLOW:
			return {
				...state,
				signUpFlow: { isActive: false, completionHandlers: [] },
			};
		case currentUserActionTypes.SET_UPLOAD_CONSENTS_WHEN_AUTHENTICATED_SUCCESSFULLY:
			return {
				...state,
				uploadConsentsWhenAuthenticatedSuccessfully: action.payload,
			};
		default:
			return state;
	}
};

export default currentUserReducer;
