import styled from "styled-components";

export const Container = styled.div`
	margin: 0px 10px;
	width: calc(100vw - 20px);
	height: calc(100vh - 70px);
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Title = styled.p`
	font-size: 50px;
	vertical-align: middle;
	text-align: center;
`;
