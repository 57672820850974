import React from "react";

import RoundedButton from "../rounded-button/rounded-button.component";
import { signInWithGoogle } from "../../firebase/firebase.utils";
import {
	RoundedButtonContainer,
	roundedButtonStyles,
	LogoImg,
	ColorText,
} from "../sign-in/sign-in.styles";
import googleLogo from "../../assets/sign-in-with-google-logo.png";
import { selectConsents } from "../../redux/consents/consents.selector";
import { connect, useDispatch, useSelector } from "react-redux";
import { showAlertAction } from "../../redux/alert/alert.actions";
import { AgreeToPPAndTCMessage } from "../../redux/alert/alert.example.messages";
import { updateConsentsAction } from "../../redux/consents/consents.actions";
import { selectIsEmbeddedInAndroidIosApp } from "../../redux/metadata/metadata.selector";
import { requestIdTokenForSignInWithGoogle } from "../../utils/CustomReactMessages.utils";
import { setUploadConsentWhenAuthenticatedSuccessfully } from "../../redux/current-user/current-user.actions";

const SignInWithGoogle = ({
	PPandTandCConsent,
	checkForPPAndTCConsent = false,
	showAlert,
	switchToLogIn = () => {},
	signUp = false,
}) => {
	const isEmbeddedInAndroidIosApp = useSelector(
		selectIsEmbeddedInAndroidIosApp
	);
	const dispatch = useDispatch();
	return (
		<div>
			<RoundedButtonContainer>
				<RoundedButton
					$FullWidth
					styles={roundedButtonStyles}
					onClick={() => {
						if (checkForPPAndTCConsent) {
							if (PPandTandCConsent === true) {
								dispatch(
									setUploadConsentWhenAuthenticatedSuccessfully(
										true
									)
								);
								if (isEmbeddedInAndroidIosApp) {
									requestIdTokenForSignInWithGoogle();

									// //warning!!!!
									// // start loading hides the elemnts which are wrapped in it so everything after start loading in this elemnt will not run
									// dispatch(startLoading);
								} else {
									signInWithGoogle(); //.then(res => {
									// 	dispatch(
									// 		uploadConsentsToFirestoreStartAction(
									// 			{
									// 				uid: res.user.uid,
									// 			}
									// 		)
									// 	);
									// });
								}
							} else {
								dispatch(
									updateConsentsAction({ underline: true })
								);
								showAlert({
									...AgreeToPPAndTCMessage,
									buttons: [
										{
											title: "go to log in",
											styles: "background-color:gray;",
											onClick: switchToLogIn,
										},
										{ title: "OK" },
									],
								});
							}
						} else {
							if (isEmbeddedInAndroidIosApp) {
								requestIdTokenForSignInWithGoogle();
								// //warning!!!!
								// // start loading hides the elemnts which are wrapped in it so everything after start loading in this elemnt will not run
								// dispatch(startLoading);
							} else {
								signInWithGoogle().catch(error => {});
							}
						}
					}}
					type={"button"}
				>
					<LogoImg src={googleLogo} alt="google" />
					<ColorText color={"#DB4437"}>
						{" "}
						{signUp ? "SIGN" : "LOG"}
					</ColorText>
					<ColorText color={"#F4B400"}>
						{" "}
						{signUp ? "UP" : "IN"}
					</ColorText>
					<ColorText color={"#0F9D58"}> WITH</ColorText>
					<ColorText color={"#4285F4"}> GOOGLE</ColorText>
				</RoundedButton>
			</RoundedButtonContainer>
		</div>
	);
};

const mapStateToProps = state => ({
	PPandTandCConsent: selectConsents(state).PPandTandCConsent,
});

const mapDispatchToProps = dispatch => ({
	showAlert: payload => dispatch(showAlertAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInWithGoogle);
