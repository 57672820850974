import styled from "styled-components";

import { breakPoints } from "../../utils/css.utils";
import { shortPromotionVideoWidth } from "../../components/short-promotion-video/short-promotion-video.styles";

export const SubscriptionPageContainer = styled.div`
	position: relative;
	width: 100%;
	min-height: calc(100vh - 70px);
	display: flex;
`;

export const BackgroundImage = styled.img`
	position: absolute;

	bottom: 0px;

	right: 0px;
	height: 60vh;
	z-index: 0;

	@media only screen and (max-width: ${breakPoints.subscriptionPage}) {
		height: unset;
		width: 80%;
	}
`;

export const OffersContainer = styled.div`
	position: absolute;
	display: grid;
	grid-template-columns:
		[first] /*calc(${shortPromotionVideoWidth} + 20px)*/ repeat(3, auto)
		//uncomment when implementing promotion video, search for this comments
		[end];
	grid-template-rows: [first] repeat(10, auto);
	flex-direction: row;
	top: 10px;
	left: calc(10px);
	margin-right: 10px;
	z-index: 800;
	align-items: flex-start;
	justify-items: center;

	@media only screen and (max-width: 1300px) {
		grid-template-columns:
			[first] /*calc(${shortPromotionVideoWidth} + 20px)*/ repeat(2, auto)
			//uncomment when implementing promotion video, search for this comments
			[end];
		position: unset;
		margin: 0px auto 50px auto;
	}
	@media only screen and (max-width: 1005px) {
		grid-template-columns: [first] /*calc(${shortPromotionVideoWidth} + 20px)*/ [end]; //uncomment when implementing promotion video, search for this comments
	}
	@media only screen and (max-width: ${breakPoints.subscriptionPage}) {
		align-items: center;
		grid-template-columns: auto;
	}
`;

export const TandCandPPContainer = styled.div`
	position: absolute;
	bottom: 5px;
	left: 10px;
	background-color: #ffffffef;
	z-index: 910;
	padding: 0px 10px;
	border-radius: 10px;
`;

export const ZIndexContainer = styled.div`
	z-index: 500;
`;

export const SubscriptionDurationToggle = styled.div`
	@media only screen and (min-width: 801px) {
		grid-column-start: 2;
		grid-column-end: end;
	}
	font-size: 20px;
`;
