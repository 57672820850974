import loadingAnimationActionTypes from "./loading-animation.types";

const INITIAL_STATE = { isLoading: true };

const loadingAnimationReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case loadingAnimationActionTypes.START_LOADING:
			return {
				...state,
				isLoading: true,
			};
		case loadingAnimationActionTypes.STOP_LOADING:
			return {
				...state,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default loadingAnimationReducer;
