import styled from "styled-components";

export const RedText = styled.a`
	color: red;
	margin: 0 2px;
	z-index: 910;

	&:hover {
		cursor: pointer;
	}
`;
