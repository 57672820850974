import { offers } from "./subscription.data";
import subscriptionActionTypes from "./subscription.types";

const INITIAL_STATE = {
	packages: offers,
	packagesFromParentApp: undefined,
};

const subscriptionReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case subscriptionActionTypes.GET_OFFERS_SUCCESS:
			var offers_to_return = [];
			action.payload.forEach(offer => {
				var to_return = {};
				offers_to_return.push({ ...to_return, ...offer });
			});
			return { ...state, packages: offers_to_return };
		case subscriptionActionTypes.SET_PACKAGES_FROM_PARENT_APP:
			return { ...state, packagesFromParentApp: action.payload };
		default:
			return state;
	}
};

export default subscriptionReducer;
