import styled, { css } from "styled-components";

import { breakPoints } from "../../utils/css.utils";

export const shortPromotionVideoWidth = "300px";

export const ButtonContainer = styled.div`
	height: 50px;
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 20px;
`;

export const MovingWhiteContainer = styled.div`
	@media screen and (max-width: ${breakPoints.subscriptionPage}) {
		display: unset;
		background-color: #f9f9f9cc;
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
		border-radius: 20px;
		padding: 10px;
		z-index: 100;
		position: fixed;
		bottom: 10px;
		left: 20px;
		right: 20px;
		transition-duration: 1s;
		-webkit-box-shadow: 0px 0px 41px -13px rgba(125, 125, 125, 1);
		-moz-box-shadow: 0px 0px 41px -13px rgba(125, 125, 125, 1);
		box-shadow: 0px 0px 41px -13px rgba(125, 125, 125, 1);
		${props => {
			if (props.$hasAlreadySubscribed)
				return css`
					margin-right: auto;
					width: 20px;
					height: 20px;
					text-align: center;
					border-radius: 50%;
					animation: bouncyAnimation 2s infinite;
					-webkit-box-shadow: 0px 0px 41px -13px #000000;
					-moz-box-shadow: 0px 0px 41px -13px #000000;
					box-shadow: 0px 0px 41px -13px #000000;
					transition-timing-function: cubic-bezier(0, 1, 0, 1);
				`;
		}}
	}
	display: none;

	@keyframes bouncyAnimation {
		0%,
		100% {
			bottom: 10px;
			padding: 10px;
			left: 10px;
		}
		50% {
			bottom: 20px;
			padding: 15px;
			left: 5px;
		}
	}
`;

export const TextButtonContainer = styled.div`
	top: 20px;
	padding-top: 18px;
`;
