import styled from "styled-components";

import { translucentWhite } from "../../constants/colours";

export const StyledLabel = styled.label`
	font-size: 25px;
`;

export const RoundedButtonContainer = styled.div`
	align-items: center;
	height: 50px;
	margin-top: 10px;
`;

export const SignInButtonStyles = `background-color:${translucentWhite};
color:black;
&:hover{
    color:white;
    background-color:#000000bb;
}`;
