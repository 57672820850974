import styled from "styled-components";

import {
	translucentWhite,
	translucentWhite2,
} from "../../../constants/colours";

export const RoundedInput = styled.input`
	border-radius: 12.5px;
	height: 40px;
	width: calc(100% - 20px);
	border: 1px solid black;
	background-color: ${translucentWhite};
	font-size: 19px;
	padding: 0 10px;
	outline: none;
	margin-bottom: 10px;
	transition-duration: 0.2s;

	&:hover {
		background-color: ${translucentWhite2};
	}
`;
