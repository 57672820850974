export const selectMetadata = state => state.metadata;

export const selectWindowInnerHeight = state =>
	selectMetadata(state).windowInnerHeight;

export const selectResizeListenerTurnOn = state =>
	selectMetadata(state).resizeListenerTurnedOn;

export const selectIsEmbeddedInAndroidIosApp = state =>
	selectMetadata(state).isEmbeddedInAndroidIosApp;

export const selectParentAppOS = state => selectMetadata(state).parentAppOS;
