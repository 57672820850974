export const selectCurrentUser = state => state.currentUser;

export const selectUID = state => selectCurrentUser(state).UID;

export const selectIsLoggedIn = state => selectCurrentUser(state).isLoggedIn;

export const selectUsername = state => selectCurrentUser(state).Username;

export const selectEmail = state => selectCurrentUser(state).email;

export const selectSignUpFlow = state => selectCurrentUser(state).signUpFlow;

export const selectIsActiveSignUpFlow = state =>
	selectSignUpFlow(state).isActive;

export const selectSignUpFlowCompletionHandlers = state =>
	selectSignUpFlow(state).completionHandlers;

export const selectUploadConsentsWhenAuthenticatedSuccessfully = state =>
	selectCurrentUser(state).uploadConsentsWhenAuthenticatedSuccessfully;
