import styled from "styled-components";

export const HorizontalContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const GrayText = styled.p`
	color: gray;
	margin: 0 2px;
	z-index: 910;
`;

export const RedText = styled.div`
	color: red;
	margin: 0 2px;
	z-index: 910;

	&:hover {
		cursor: pointer;
	}
`;

export const Container = styled.div`
	margin: 7px 0;
	font-size: 13px;
`;
