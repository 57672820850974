import styled from "styled-components";
import { opaqueLightGray } from "../../constants/colours";

const SignInUpForm = styled.form`
	text-align: left;
	margin: 0 auto;
	min-width: 20%;
	max-width: 303px;
	padding: 0 20px;
`;

export const RoundedContainer = styled.div`
	margin: 10px;
	padding: 20px;
	border-radius: 50px;
	background-color: ${props => {
		if (props.opacity) {
			return `#ffffff${parseInt(props.opacity * 255).toString(16)}`;
		}
		return opaqueLightGray;
	}};
	-webkit-backdrop-filter: blur(6px);
	backdrop-filter: blur(6px);
	text-align: center;
	overflow: scroll;

	max-height: calc(
		${props => props.innerHeight}px -
			${props => (props.$acknowledgeHeader ? 150 : 60)}px
	);

	//remove scrollbar view but keep scrollbar functionality
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
`;

export default SignInUpForm;
