const subscriptionActionTypes = {
	GET_OFFERS_START: "GET_OFFERS_START",
	GET_OFFERS_FAILURE: "GET_OFFERS_FAILURE",
	GET_OFFERS_SUCCESS: "GET_OFFERS_SUCCESS",
	REDIRECT_TO_CHECKOUT_START: "REDIRECT_TO_CHECKOUT",
	REDIRECT_TO_CHECKOUT_FAILURE: "REDIRECT_TO_CHECKOUT_FAILURE",
	REDIRECT_TO_CHECKOUT_SUCCESS: "REDIRECT_TO_CHECKOUT_SUCCESS",
	SET_PACKAGES_FROM_PARENT_APP: "SET_PACKAGES_FROM_PARENT_APP",
	CANCEL_SUBSCRIPTION_START: "CANCEL_SUBSCRIPTION_START",
	CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
	CANCEL_SUBSCRIPTION_FAILURE: "CANCEL_SUBSCRIPTION_FAILURE",
	CANCEL_SUBSCRIPTION_CONFIRMATION: "CANCEL_SUBSCRIPTION_CONFIRMATION",
	CHECK_PREREQUISITS_START: "CHECK_PREREQUISITS_START",
	CHECK_PREREQUISITS_SUCCESS: "CHECK_PREREQUISITS_SUCCESS",
	CHECK_PREREQUISITS_FAILURE: "CHECK_PREREQUISITS_FAILURE",
};

export default subscriptionActionTypes;
