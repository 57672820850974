const consentsActionTypes = {
	UPDATE_CONSENTS: "UPDATE_CONSENTS",
	UPDATE_PPANDTANDCCONSENT: "UPDATE_PPANDTANDCCONSENT",
	UPDATE_REMINDERSCONSENT: "UPDATE_REMINDERSCONSENT",
	UPDATE_MARKETINGCONSENT: "UPDATE_MARKETINGCONSENT",
	RESET_CONSENTS: "RESET_CONSENTS",
	UPLOAD_CONSENTS_TO_FIRESTORE_START: "UPLOAD_CONSENTS_TO_FIRESTORE_START",
	UPLOAD_CONSENTS_TO_FIRESTORE_SUCCESS:
		"UPLOAD_CONSENTS_TO_FIRESTORE_SUCCESS",
	UPLOAD_CONSENTS_TO_FIRESTORE_FAILURE:
		"UPLOAD_CONSENTS_TO_FIRESTORE_FAILURE",
	FETCH_CONSENTS_FROM_FIRESTORE_START: "FETCH_CONSENTS_FROM_FIRESTORE_START",
	FETCH_CONSENTS_FROM_FIRESTORE_FAILURE:
		"FETCH_CONSENTS_FROM_FIRESTORE_FAILURE",
	FETCH_CONSENTS_FROM_FIRESTORE_SUCCESS:
		"FETCH_CONSENTS_FROM_FIRESTORE_SUCCESS",
	MANAGE_CONSENTS_START: "MANAGE_CONSENTS_START",
	MANAGE_CONSENTS_FAILURE: "MANAGE_CONSENTS_FAILURE",
	MANAGE_CONSENTS_SUCCESS: "MANAGE_CONSENTS_SUCCESS",
};

export default consentsActionTypes;
