import React from "react";
import TermsAndConditionsTextButton from "../terms-and-condtions-text-button/terms-and-conditions-text-button.component";
import PrivacyPolicy from "../privacy-policy-text-button/privacy-policy-text-button.compnent";
import { selectConsents } from "../../redux/consents/consents.selector";
import { connect } from "react-redux";
import { updateConsentsAction } from "../../redux/consents/consents.actions";
import { CustomCheckboxInput } from "./consents.styles";

class Consents extends React.Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
	}

	onChange(event) {
		const name = event.target.id;
		this.props.updateConsents({
			[name]: event.target.checked,
			underline: false,
		});
	}

	render() {
		return (
			<div>
				<div
					style={{
						display: "flex",
						paddingBottom: "10px",
						gap: "10px",
					}}
				>
					<CustomCheckboxInput
						id="PPandTandCConsent"
						type="checkbox"
						checked={this.props.PPandTandCConsent}
						onChange={this.onChange}
					/>
					<div
						style={{
							textDecoration: `${
								this.props.underline ? "underline" : ""
							}`,
						}}
					>
						I agree to the <TermsAndConditionsTextButton /> and{" "}
						<PrivacyPolicy />
					</div>
				</div>
				<div
					style={{
						display: "flex",
						paddingBottom: "10px",
						gap: "10px",
					}}
				>
					<CustomCheckboxInput
						id="RemindersConsent"
						type="checkbox"
						checked={this.props.RemindersConsent}
						onChange={this.onChange}
					/>
					<div>I agree to receive reminders about new lessons</div>
				</div>
				<div style={{ display: "flex", gap: "10px" }}>
					<CustomCheckboxInput
						id="MarketingConsent"
						type="checkbox"
						checked={this.props.MarketingConsent}
						onChange={this.onChange}
					/>
					<div>
						I agree to receive newsletters and promotional emails
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...selectConsents(state),
});

const mapDispatchToProps = dispatch => ({
	updateConsents: payload => dispatch(updateConsentsAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Consents);
