import styled from "styled-components";
import { Link } from "react-router-dom";

import { lightGray, black, opaqueLightGray } from "../../constants/colours";

const logoPadding = "5px";
const barButtonsContainerMargin = "10px";
const headerHeight = "70px";
export const breakePointForHeader = 900;

export const BlankSpacer = styled.div`
	height: ${headerHeight};
`;

export const HeaderContainer = styled.div`
	position: fixed;
	z-index: 900;
	background-color: white;
	top: 0;
	left: 0;
	right: 0;

	height: ${headerHeight};
	border-bottom: 1px solid ${lightGray};
	display: flex;
	font-family: "Bebas Neue";
`;

export const Logo = styled.img`
	height: calc(100% - (${logoPadding}));
	padding: ${logoPadding};
	padding-left: 20px;
	padding-bottom: 0px;
	align-items: left;
	object-fit: contain;
	transition-duration: 0.8s;
	width: 200px;
	cursor: pointer;

	@media only screen and (min-width: ${breakePointForHeader}px) {
		&:hover {
			transform: scale(1.2);
			-webkit-transform: scale(1.2);
		}
	}
`;

export const BarButtonsContainer = styled.div`
	height: calc(100% - ${barButtonsContainerMargin});
	width: calc(100% - 250px);
	display: flex;
	top: 0px;
	margin: ${barButtonsContainerMargin};
	margin-left: auto;
	margin-bottom: 0px;
	align-items: center;
	justify-content: flex-end;

	@media only screen and (max-width: ${breakePointForHeader}px) {
		position: fixed;
		top: 0px;
		right: 0px;
		flex-direction: column;
		z-index: 900;
		background-color: white;
		margin: 0;
		height: calc(100% - 20px);
		width: 250px;
		-webkit-backdrop-filter: blur(7px);
		backdrop-filter: blur(7px);
		background-color: #00000033;
		background-color: ${opaqueLightGray};
		align-items: flex-end;
		padding: 10px;
		padding-right: unset;
		justify-content: flex-start;
	}
`;

const setColor = props => {
	if (props.color) {
		return `
		color:${props.color};
		
		&:hover{
			color:#c60000;
		}
		
		`;
	}
	return "";
};

export const BarButtonItem = styled(Link)`
	display: flex;
	color: gray;
	font-size: 40px;
	margin: 0px 20px;
	text-decoration: none;

	transition-duration: 0.8s;

	@media only screen and (min-width: ${breakePointForHeader}px) {
		&:hover {
			transform: scale(1.1);
			transform: rotate(-2deg);
			color: ${black};
		}
	}
	${setColor}
`;

export const SmallText = styled.p`
	color: gray;
	font-size: 15px;
	margin: 0;
	text-align: left;
	padding: 6px 3px 0 3px;
`;

export const BarButtonIcon = styled.img`
	/* display: flex; */
	height: 50px;
	margin: auto 20px;

	transition-duration: 4s;
	transition-property: transform;

	&:hover {
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
	}
`;

export const BlackStripesContainer = styled.div`
	display: flex;
	position: absolute;
	top: 20px;
	right: 20px;
	flex-direction: column;
	height: 30px;
	justify-content: space-around;
	cursor: pointer;
`;

export const BlackStripe = styled.div`
	width: 30px;
	height: 3px;
	background-color: black;
`;

export const XButtonContainer = styled.div`
	position: relative;
	height: 30px;
	width: 30px;
	margin: 0 20px 5px;
	cursor: pointer;
`;

export const XButtonPartOne = styled.div`
	position: absolute;
	top: 15px;
	right: 0;
	width: 30px;
	height: 3px;
	background-color: black;
	transform: rotate(45deg);
`;

export const XButtonPartTwo = styled.div`
	position: absolute;
	top: 15px;
	right: 0;
	width: 30px;
	height: 3px;
	background-color: black;
	transform: rotate(-45deg);
`;
