import React from "react";
import { useSelector } from "react-redux";
import { selectIsEmbeddedInAndroidIosApp } from "../../redux/metadata/metadata.selector";
import { PriceContainer } from "./price.styles";

const Price = ({
	hasAlreadySubscribed,
	pckg = {},
	packageFromParentApp,
	style,
}) => {
	const isEmbedded = useSelector(selectIsEmbeddedInAndroidIosApp);
	const { FreeTrialPeriod, StripePrice, BillingCycle } = pckg;

	if (isEmbedded) {
		return (
			<PriceContainer {...{ style }}>
				{hasAlreadySubscribed ? (
					""
				) : (
					<div>
						<b>30 days free,</b> then{" "}
					</div>
				)}
				{packageFromParentApp?.product?.priceString || "=="}{" "}
				{packageFromParentApp?.product?.subscriptionPeriod === "P1Y"
					? "per year"
					: "per month"}
			</PriceContainer>
		);
	}
	return (
		<PriceContainer {...{ style }}>
			{FreeTrialPeriod ? (
				hasAlreadySubscribed ? (
					""
				) : (
					<div>
						<b>{FreeTrialPeriod} days free,</b> then{" "}
					</div>
				)
			) : null}
			{StripePrice ? StripePrice.currency : "=="}{" "}
			{StripePrice ? StripePrice.unit_amount / 100 : "=="}{" "}
			{BillingCycle === "annual" ? "per year" : "per month"}
		</PriceContainer>
	);
};

export default Price;
