import React from "react";
import { RedText } from "./terms-and-conditions-text-button.styles";

const TermsAndConditionsTextButton = () => {
	const TandCClicked = () => {
		window.open("/terms-and-conditions");
	};
	return <RedText onClick={TandCClicked}>terms and conditions</RedText>;
};

export default TermsAndConditionsTextButton;
