import liveLessonActionTypes from "./live-lesson.types";

const INITIAL_STATE = {
	liveLessonUrl: "",
	chatUrl: "",
};

const liveLessonReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case liveLessonActionTypes.GET_LIVE_LESSON_URLS_SUCCESS:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

export default liveLessonReducer;
