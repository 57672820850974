import MetadataActionTypes from "./metadata.types";

export const updateWindowInnerHeightAction = () => ({
	type: MetadataActionTypes.UPDATE_WINDOW_INNER_HEIGHT,
});

export const turnOnResizeListenerAction = () => ({
	type: MetadataActionTypes.TURN_ON_RESIZE_LISTENER,
});

export const turnOffResizeListenerAction = () => ({
	type: MetadataActionTypes.TURN_OFF_RESIZE_LISTENER,
});

export const updateMetadata = payload => ({
	type: MetadataActionTypes.UPDATE_METADATA,
	payload: payload,
});
