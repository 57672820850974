const currentUserActionTypes = {
	ADD_USER: "ADD_USER",
	REMOVE_USER: "REMOVE_USER",
	START_SIGN_UP_FLOW: "START_SIGN_UP_FLOW",
	END_SIGN_UP_FLOW: "END_SIGN_UP_FLOW",
	SET_UPLOAD_CONSENTS_WHEN_AUTHENTICATED_SUCCESSFULLY:
		"SET_UPLOAD_CONSENTS_WHEN_AUTHENTICATED_SUCCESSFULLY",
};

export default currentUserActionTypes;
