import liveLessonActionTypes from "./live-lesson.types";

export const getLiveLessonUrlsStart = () => ({
	type: liveLessonActionTypes.GET_LIVE_LESSON_URLS_START,
});

export const getLiveLessonUrlsFailure = () => ({
	type: liveLessonActionTypes.GET_LIVE_LESSON_URLS_FAILURE,
});
export const getLiveLessonUrlsSuccess = Urls => ({
	type: liveLessonActionTypes.GET_LIVE_LESSON_URLS_SUCCESS,
	payload: Urls,
});
