import styled from "styled-components";
import { almostBlack } from "../../constants/colours";
import { breakPoints } from "../../utils/css.utils";

export const PriceContainer = styled.p`
	margin: 2px;
	color: ${almostBlack};
	text-align: center;
	@media only screen and (min-width: ${breakPoints.subscriptionPage}) {
		display: flex;
		gap: 5px;
	}
`;
