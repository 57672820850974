import React, { useEffect } from "react";
import CustomEventNames from "../../utils/customEventNames.utils";
import { useDispatch } from "react-redux";
import { updateMetadata } from "../../redux/metadata/metadata.actions";
import {
	appleProvider,
	auth,
	googleProvider,
} from "../../firebase/firebase.utils";
import { setPackagesFromParentApp } from "../../redux/subscription/subscription.actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { stopLoading } from "../../redux/loading-animation/loading-animation.actions";
import { signInWithCredential } from "firebase/auth";

const ReactNativeHandler = () => {
	const dispatch = useDispatch();

	const history = useHistory();
	useEffect(() => {
		updateReduxWithWindowVariables();
		// window.addEventListener(
		// 	CustomEventNames.UPDATE_REDUX_WITH_WINDOW_VARIABLES,
		// 	updateReduxWithWindowVariables
		// );
		window.addEventListener(
			CustomEventNames.LOG_IN_WITH_ID_TOKEN,
			logInWithIdToken
		);
		window.addEventListener(
			CustomEventNames.PASS_RC_PACKAGES,
			passRcPackages
		);
		window.addEventListener(
			CustomEventNames.REDIRECT_TO_PAGE,
			redirectToPage
		);
		window.addEventListener(
			CustomEventNames.STOP_LOADING_ANIMATION,
			stopLoadingAnimation
		);

		passRcPackages();

		return () => {
			// window.removeEventListener(
			// 	CustomEventNames.UPDATE_REDUX_WITH_WINDOW_VARIABLES,
			// 	updateReduxWithWindowVariables
			// );
			window.removeEventListener(
				CustomEventNames.LOG_IN_WITH_ID_TOKEN,
				logInWithIdToken
			);
			window.removeEventListener(
				CustomEventNames.PASS_RC_PACKAGES,
				passRcPackages
			);
			window.removeEventListener(
				CustomEventNames.REDIRECT_TO_PAGE,
				redirectToPage
			);
			window.removeEventListener(
				CustomEventNames.STOP_LOADING_ANIMATION,
				stopLoadingAnimation
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function updateReduxWithWindowVariables(event) {
		const isEmbeddedInAndroidIosApp = window.isEmbeddedInAndroidIosApp;

		if (isEmbeddedInAndroidIosApp) {
			const parentAppVersion = window.parentAppVersion;
			const parentAppBuildNumber = window.parentAppBuildNumber;
			const parentAppBundleId = window.parentAppBundleId;
			const parentAppOS = window.parentAppOS;

			dispatch(
				updateMetadata({
					isEmbeddedInAndroidIosApp,
					parentAppVersion,
					parentAppBuildNumber,
					parentAppBundleId,
					parentAppOS,
				})
			);
		}
	}

	async function logInWithIdToken(event) {
		const idToken = event.detail.idToken;
		const provider = event.detail.provider;
		var credential = undefined;
		if (provider === "Google") {
			credential = googleProvider.credential({ idToken });
		} else if (provider === "Apple") {
			const nonce = event.detail.nonce;

			credential = appleProvider.credential({
				idToken: idToken,
				rawNonce: nonce,
			});
		}

		await signInWithCredential(auth, credential).catch(err => {
			console.error(err);
			alert(err.message);
		});
	}

	function passRcPackages(event) {
		const { rawPackagesFromParentApp } = window;
		if (rawPackagesFromParentApp) {
			// const packages = JSON.parse(rawPackagesFromParentApp);
			var formatedPackages = {};
			rawPackagesFromParentApp.forEach(pckg => {
				formatedPackages[pckg.identifier] = pckg;
			});
			dispatch(setPackagesFromParentApp(formatedPackages));
		} else {
			if (window.isEmbeddedInAndroidIosApp)
				console.error(
					"didn't find any subscription packages from parent React Native app"
				);
		}
	}

	function redirectToPage(event) {
		const page = event.detail.page;
		console.log("redirecting to webpage in website", page);
		history.push(page);
	}

	function stopLoadingAnimation(event) {
		dispatch(stopLoading());
	}
	return <div></div>;
};

export default ReactNativeHandler;
