const CustomReactMessages = {
	requesting_idtoken_for_sign_in_with_google:
		"requesting_idtoken_for_sign_in_with_google",
	requesting_idtoken_and_nonce_for_sign_in_with_apple:
		"requesting_idtoken_and_nonce_for_sign_in_with_apple",
	log_in_to_revenuecat_in_rn: "log_in_to_revenuecat_in_rn",
	purchase_rc_package: "purchase_rc_package",
	restore_rc_purchases: "restore_rc_purchases",
};

export const requestIdTokenForSignInWithGoogle = () => {
	const message = {
		type: CustomReactMessages.requesting_idtoken_for_sign_in_with_google,
	};

	window.ReactNativeWebView.postMessage(JSON.stringify(message));
};

export const requestIdTokenAndNonceForSignInWithApple = () => {
	const message = {
		type: CustomReactMessages.requesting_idtoken_and_nonce_for_sign_in_with_apple,
	};

	window.ReactNativeWebView.postMessage(JSON.stringify(message));
};

export const logInToRevenuecatInRn = uid => {
	const message = {
		type: CustomReactMessages.log_in_to_revenuecat_in_rn,
		payload: { uid },
	};

	window.ReactNativeWebView.postMessage(JSON.stringify(message));
};

export const purchaseRCPackage = (pckg, oldProductID = undefined) => {
	const message = {
		type: CustomReactMessages.purchase_rc_package,
		payload: { package: pckg, oldProductID },
	};
	window.ReactNativeWebView.postMessage(JSON.stringify(message));
};

export const restoreRCPurchase = () => {
	const message = {
		type: CustomReactMessages.restore_rc_purchases,
	};
	window.ReactNativeWebView.postMessage(JSON.stringify(message));
};

export default CustomReactMessages;
