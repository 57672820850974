import { all, call, takeLatest, put } from "redux-saga/effects";
import {
	getFlashcardsFailure,
	getFlashcardsSuccess,
} from "./flashcards.actions";
import FlashcardsActionTypes from "./flashcards.types";
import { getFlashcardsCloudFunction } from "../../firebase/firebase.utils";
import { showAlertAction } from "../alert/alert.actions";

function* getFlashcards(action) {
	const VideoID = action.payload;

	try {
		const {
			data: { flashcards },
		} = yield getFlashcardsCloudFunction({ VideoID });

		yield put(getFlashcardsSuccess(flashcards));
	} catch (error) {
		console.error(error);
		yield put(getFlashcardsFailure());
		yield put(
			showAlertAction({
				title: "No access",
				description: "Please Sign in or subscribe to BASIC or VIP.",
				buttons: [
					{
						title: "Log In",
						redirectTo: "/sign-in-up",
						styles: "margin-left:10px;background-color:#333333;",
					},
					{
						title: "Subscribe",
						redirectTo: "/subscriptions",
						styles: "margin-left:10px;background-color:#333333;",
					},
				],
			})
		);
	}
}

export function* onGetFlashcardsStart() {
	yield takeLatest(FlashcardsActionTypes.GET_FLASHCARDS_START, getFlashcards);
}

export function* flashcardsSagas() {
	yield all([call(onGetFlashcardsStart)]);
}
