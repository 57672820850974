import consentsActionTypes from "./consents.types";

export const updateConsentsAction = payload => ({
	type: consentsActionTypes.UPDATE_CONSENTS,
	payload,
});

export const resetConsentsAction = () => ({
	type: consentsActionTypes.RESET_CONSENTS,
});

export const uploadConsentsToFirestoreStartAction = payload => ({
	type: consentsActionTypes.UPLOAD_CONSENTS_TO_FIRESTORE_START,
	payload,
});

export const uploadConsentsToFirestoreFailureAction = () => ({
	type: consentsActionTypes.UPLOAD_CONSENTS_TO_FIRESTORE_FAILURE,
});

export const uploadConsentsToFirestoreSuccessAction = () => ({
	type: consentsActionTypes.UPLOAD_CONSENTS_TO_FIRESTORE_SUCCESS,
});

export const fetchConsentsFromFirestoreStartAction = () => ({
	type: consentsActionTypes.FETCH_CONSENTS_FROM_FIRESTORE_START,
});

export const fetchConsentsFromFirestoreFailureAction = () => ({
	type: consentsActionTypes.FETCH_CONSENTS_FROM_FIRESTORE_FAILURE,
});

export const fetchConsentsFromFirestoreSuccessAction = () => ({
	type: consentsActionTypes.FETCH_CONSENTS_FROM_FIRESTORE_SUCCESS,
});

export const manageConsentsStartAction = payload => ({
	type: consentsActionTypes.MANAGE_CONSENTS_START,
	payload,
});

export const manageConsentsFailureAction = payload => ({
	type: consentsActionTypes.MANAGE_CONSENTS_FAILURE,
	payload,
});

export const manageConsentsSuccessAction = payload => ({
	type: consentsActionTypes.MANAGE_CONSENTS_SUCCESS,
	payload,
});
