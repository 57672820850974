import miniLessonsActionTypes from "./mini-lessons.types";
import miniLessonsTypes from "./mini-lessons.types";

const placeholderIDsIndex = [
	"EuikRZuSK1iRQSDQn4Nx",
	"xOkSSI1RuDedCaXqBzMS",
	"Bgd1itlvPkL4E90sUY0L",
	"ZJtYMcjMlXGZYCqyvtcZ",
	"huVHRm1q3uJU93qXkcK2",
	"wKJIfdsfoAbA7xPKbwYr",
	"bWaCSfS7h3YJc2dt7nrH",
	"eluY8wzUNEdrgtLj2ezZ",
	"ZKgK0XpUfu2W7AF9hABJ",
	"U07b5VqUdyrkBtfgqUbE",
	"d2N3OPBvz4xNtowOy6u7",
	"sSAoki8WadJuUg4cmLPE",
];

const INITIAL_STATE = {
	IDsIndex: placeholderIDsIndex,
	current: { ID: "", VideoThumbnailURL: "", VideoHLSurl: "", Text: "" },
	newestMiniLesson: { DocID: "", VideoThumbnailURL: "" },
	IDsIndexRandomized: placeholderIDsIndex,
	currentIndexElementNumber: 0,
};

const miniLessonsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case miniLessonsTypes.GET_MINI_LESSONS_IDS_INDEX_SUCCESS:
			var IDsIndexRandomized;
			IDsIndexRandomized = state.IDsIndex.sort(
				(a, b) => 0.5 - Math.random()
			);
			return { ...state, IDsIndex: action.payload, IDsIndexRandomized };
		case miniLessonsActionTypes.CHOOSE_RANDOM_MINI_LESSON_SUCCESS:
			return { ...state, currentIndexElementNumber: action.payload };
		case miniLessonsActionTypes.GET_NEWEST_MINI_LESSON_SUCCESS:
			if (action.payload.DocID) {
				return {
					...state,
					newestMiniLesson: { ...action.payload },
				};
			}
			return { ...state, current: action.payload };
		case miniLessonsActionTypes.GET_MINI_LESSON_SUCCESS:
			return { ...state, current: action.payload };
		default:
			return state;
	}
};

export default miniLessonsReducer;
