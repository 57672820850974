import MetadataActionTypes from "./metadata.types";

const INITIAL_STATE = {
	windowInnerHeight: window.innerHeight,
	resizeListenerTurnedOn: false,
	isEmbeddedInAndroidIosApp: false,
	unsubscribeFbAuthListener: undefined,
};

const metadataReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case MetadataActionTypes.UPDATE_WINDOW_INNER_HEIGHT:
			return {
				...state,
				windowInnerHeight: window.innerHeight,
			};
		case MetadataActionTypes.TURN_ON_RESIZE_LISTENER:
			return {
				...state,
				resizeListenerTurnedOn: true,
			};
		case MetadataActionTypes.TURN_OFF_RESIZE_LISTENER:
			return {
				...state,
				resizeListenerTurnedOn: false,
			};
		case MetadataActionTypes.UPDATE_METADATA:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default metadataReducer;
