import React, { useState, useEffect } from "react";

import { HlsVideoPlayer, RegularVideoPlayer } from "./video.styles";
import LoadingAnimationComponent from "../loading-animation/loading-animation.component";

const VideoPlayer = ({
	srcVideo,
	autoPlay,
	aspectRatio = [1920, 1080],
	controls = true,
	transitionDuration = 1,
	muted = false,
	loop = false,
	style = {},
	isHLSType = true,
	propsVideoPlayer = {},
	onCanPlay = () => {},
}) => {
	const [videoHeight, setVideoHeight] = useState(100);
	const [isLoading, setIsLoading] = useState(true);
	const [controlsState, setControlsState] = useState(false);
	//the Kafele video does not work on this video player

	const resizeVideoPlayer = () => {
		const videoPlayerWidth = document.getElementById(
			`HlsVideoPlayer:${srcVideo}`
		)?.offsetWidth;
		if (videoPlayerWidth)
			setVideoHeight(
				(videoPlayerWidth * aspectRatio[1]) / aspectRatio[0]
			);
	};

	useEffect(() => {
		setIsLoading(true);
		setControlsState(false);
		window.removeEventListener("load", resizeVideoPlayer);
		window.removeEventListener("resize", resizeVideoPlayer);

		window.addEventListener("resize", resizeVideoPlayer);
		window.addEventListener("load", resizeVideoPlayer);

		resizeVideoPlayer();
		return () => {
			window.removeEventListener("load", resizeVideoPlayer);
			window.removeEventListener("resize", resizeVideoPlayer);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [srcVideo]);

	const VideoPlayer = isHLSType ? HlsVideoPlayer : RegularVideoPlayer;
	return (
		<div width="100%" style={{ position: "relative", ...style }}>
			<VideoPlayer
				id={"HlsVideoPlayer:" + srcVideo}
				className={"HlsVideoPlayer"}
				src={srcVideo + "#t=0.1"}
				autoPlay={autoPlay}
				controls={controlsState}
				height={`${videoHeight}px`}
				transitionduration={transitionDuration}
				muted={muted}
				loop={loop}
				playsInline
				onCanPlayThrough={() => {
					setIsLoading(false);
					console.log("can play through");
					setControlsState(controls);
					onCanPlay();
				}}
				{...propsVideoPlayer}
			></VideoPlayer>

			<LoadingAnimationComponent
				overrideIsLoading={isLoading}
				style={{
					position: "absolute",
					top: "calc(50% - 40px)",
					left: "calc(50% - 40px)",
				}}
			/>
		</div>
	);
};

export default VideoPlayer;
