import styled from "styled-components";

const Background = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	inset: 0px;
	background-color: #000000aa;
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: ${props => {
		if (props.zIndex) return props.zIndex;
		else return 950;
	}};
`;

export default Background;
