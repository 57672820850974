import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import {
	OfferContainer,
	ButtonContainer,
	OfferTitle,
	Divider,
	Benefits,
	BenefitsTitle,
	Information,
} from "./offer.styles";
import RoundedButton from "../rounded-button/rounded-button.component";

import { selectIsLoggedIn } from "../../redux/current-user/current-user.selector";
import {
	cancelSubscriptionStart,
	redirectToCheckoutStart,
} from "../../redux/subscription/subscription.actions";
import {
	createStripeCardUpdateCheckoutSession,
	uncancelStripeSubscription,
} from "../../firebase/firebase.utils";
import {
	startLoading,
	stopLoading,
} from "../../redux/loading-animation/loading-animation.actions";
import { showAlertAction } from "../../redux/alert/alert.actions";
import { offerID, stripePromise } from "../../stripe/stripe.utils";
import LoadingAnimationComponent from "../loading-animation/loading-animation.component";

import { selectParentAppOS } from "../../redux/metadata/metadata.selector";
import Price from "../price/price.component";

const Offer = payload => {
	let {
		Title,
		PromotionText,
		StripePriceID,
		redirectToCheckout,
		currentSubscriptions,
		startLoadingAnimation,
		stopLoadingAnimation,
		showAlert,
		ProductID,
		// productIDsofTheStripeSubscriptionAssignedToRCUser,
		identifier,
		isEmbedded,
		packageFromParentApp, //not the same as packagesFromParentApp. It has only one corresponding package
		ButtonColour,
		FreeTrialPeriod,
		Tier,
		cancelSubscription,
		mostCurrentProduct,
		hasAlreadySubscribed,
	} = payload;

	let [information, setInformation] = useState("");

	const parentAppOS = useSelector(selectParentAppOS);

	const isCurrentSubscription = Object.keys(currentSubscriptions).some(
		id => id === ProductID
	);

	const [willCancel, setWillCancel] = useState(false);

	useEffect(() => {
		if (isCurrentSubscription === true) {
			//get current subscription that cancels and update cancelText
			Object.keys(currentSubscriptions).forEach(id => {
				if (
					id === ProductID &&
					currentSubscriptions[id].cancels === true
				) {
					var t = new Date(currentSubscriptions[id].expires_date);
					setInformation("canceles on " + t.toDateString());
					setWillCancel(true);
				} else if (
					id === ProductID &&
					currentSubscriptions[id].cancels === false
				) {
					var t = new Date(currentSubscriptions[id].expires_date);
					setInformation("renews on " + t.toDateString());
				}
			});
		} else {
			if (mostCurrentProduct?.Tier >= Tier) {
				const date = new Date(mostCurrentProduct.expires_date);
				setInformation(`first payment on: ${date.toDateString()}`);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCurrentSubscription, mostCurrentProduct]);

	const CallToActionButtonClicked = async () => {
		if (!isCurrentSubscription) {
			if (!mostCurrentProduct) {
				//there are no active subscriptions or all subscriptons will expire
				redirectToCheckout({
					packageFromParentApp,
					packageID: identifier,
					pckg: payload,
				});
			} else if (mostCurrentProduct) {
				//there is a subscription that is active and will or will not cancel

				//upgrade, downgrade or crossgrade subscription
				if (isEmbedded) {
					//change subscription product in parent app
					redirectToCheckout({
						StripePriceID,
						subscriptionEntitlementName: Title,
						packageFromParentApp,
						mostCurrentProductID: mostCurrentProduct.ProductID, //for upgrading and downgrading subscriptions in google play store
						pckg: payload,
					});
				} else {
					redirectToCheckout({
						StripePriceID,
						ProductID,
						PackageID: identifier,
						OfferID: offerID,
						changeStripeSubscription: true,
						pckg: payload,
					});
					//change subsciption item
				}
			}
		} else if (
			isCurrentSubscription &&
			willCancel &&
			mostCurrentProduct?.ProductID === ProductID
		) {
			//resubscribe
			startLoadingAnimation();
			if (isEmbedded) {
				redirectToCheckout({
					StripePriceID,
					subscriptionEntitlementName: Title,
					packageFromParentApp,
					pckg: payload,
				});
			} else {
				uncancelStripeSubscription({
					productIDToUncancel: ProductID,
				}).then(res => {
					stopLoadingAnimation();
					window.location.reload();
				});
			}
		} else if (
			isCurrentSubscription &&
			!willCancel &&
			mostCurrentProduct?.ProductID === ProductID
		) {
			//cancel subscription
			cancelSubscription();
		}
	};
	const UpdateCardDetailsWasClicked = async () => {
		startLoadingAnimation();
		if (isEmbedded) {
			if (parentAppOS === "android") {
				//update card detail in parent app
				window.open(
					"https://play.google.com/store/account/subscriptions",
					"_blank"
				);
			} else if (parentAppOS === "ios") {
				//update card detail in parent app
				window.open(
					"https://apps.apple.com/account/subscriptions",
					"_blank"
				);
				// window.open("app-settings:", "_blank");
			}
			stopLoadingAnimation();
		} else {
			createStripeCardUpdateCheckoutSession()
				.then(async res => {
					const session = res.data;
					const stripe = await stripePromise;
					stripe.redirectToCheckout({
						sessionId: session.id,
					});
				})
				.catch(error => {
					stopLoadingAnimation();
					showAlert({
						title: "Something went wrong.",
						description: "try again",
					});
				});
		}
	};

	function SubscribeButtonText({ mostCurrentProduct }) {
		console.log(mostCurrentProduct);
		console.log(Tier);
		if (isCurrentSubscription && !willCancel) return "cancel";
		else if (
			isCurrentSubscription &&
			willCancel &&
			mostCurrentProduct?.ProductID !== ProductID
		)
			return "";
		else if (
			isCurrentSubscription &&
			willCancel &&
			mostCurrentProduct?.ProductID === ProductID
		)
			return "resubscribe";
		//there is no subscription or all subscriptions will cancel
		else if (!mostCurrentProduct || mostCurrentProduct.cancels) {
			if (hasAlreadySubscribed || !FreeTrialPeriod) {
				return "SUBSCRIBE";
			} else {
				return "TRY OUT";
			}
		} else if (mostCurrentProduct?.Tier > Tier) {
			return "DOWNGRADE";
		} else if (mostCurrentProduct?.Tier < Tier) {
			return "UPGRADE";
		} else if (mostCurrentProduct?.Tier === Tier) {
			return "SWITCH";
		} else {
			return "error";
		}
	}

	return (
		<OfferContainer>
			<OfferTitle id={identifier + "_title"}>{Title}</OfferTitle>

			<LoadingAnimationComponent>
				<div>
					<BenefitsTitle>What you get:</BenefitsTitle>
					{PromotionText
						? PromotionText.map(Text => (
								<div key={Text}>
									<Benefits>{Text}</Benefits>
									<Divider />
								</div>
						  ))
						: null}
				</div>

				<ButtonContainer id={identifier + "_subscribe_button"}>
					{!information ? (
						<Information
							style={{
								textAlign: "center",
								width: "100%",
								color: "#6d6d6d",
							}}
						>
							auto renews, cancel anytime
						</Information>
					) : null}

					<Information>{information}</Information>

					{isCurrentSubscription &&
					willCancel &&
					mostCurrentProduct?.ProductID !== ProductID ? null : (
						<RoundedButton
							onClick={CallToActionButtonClicked}
							$FullWidth
							styles={
								isCurrentSubscription
									? "background-color:black"
									: ButtonColour
									? `background-color:${ButtonColour}`
									: null
							}
						>
							<SubscribeButtonText
								mostCurrentProduct={mostCurrentProduct}
							/>
						</RoundedButton>
					)}
				</ButtonContainer>

				{isCurrentSubscription && !willCancel ? (
					<ButtonContainer styles={"padding-top: 10px;"}>
						<RoundedButton
							$FullWidth
							styles={"background-color:gray"}
							onClick={UpdateCardDetailsWasClicked}
						>
							update card info
						</RoundedButton>
					</ButtonContainer>
				) : null}
				<Price
					{...{
						hasAlreadySubscribed,
						pckg: payload,
						packageFromParentApp,
					}}
				/>
			</LoadingAnimationComponent>
		</OfferContainer>
	);
};

const mapStateToProps = state => ({
	isLoggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = dispatch => ({
	redirectToCheckout: payload => dispatch(redirectToCheckoutStart(payload)),
	startLoadingAnimation: () => dispatch(startLoading()),
	stopLoadingAnimation: () => dispatch(stopLoading()),
	showAlert: alert => dispatch(showAlertAction(alert)),
	cancelSubscription: () => dispatch(cancelSubscriptionStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
