import styled from "styled-components";

export const Button = styled.button`
	background-color: red;
	color: white;
	justify-self: flex-end;
	border: none;
	font-family: "Bebas Neue";
	font-size: ${props => props.fontSize + "px"};
	border-radius: ${props => props.$borderRadius + "px"};
	padding: 4px 10px 8px 10px;
	text-align: center;
	height: 100%;
	overflow: hidden;
	cursor: pointer;
	transition-duration: 0.3s;

	&:hover {
		background-color: black;
	}

	${props => {
		if (props.$FullWidth) {
			return `width:100%;`;
		}
	}}
	${props => {
		if (props.$styles) {
			return props.$styles;
		}
	}}
`;
