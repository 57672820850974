const LessonsActionTypes = {
	ADD_LESSONS: "ADD_LESSONS",
	SET_CURRENT_LESSON: "SET_CURRENT_LESSON",
	GET_SECRET_DATA_FOR_CURRENT_LESSON_START:
		"GET_SECRET_DATA_FOR_CURRENT_LESSON",
	GET_SECRET_DATA_FOR_CURRENT_LESSON_SUCCESS:
		"GET_SECRET_DATA_FOR_CURRENT_LESSON_SUCCESS",
	GET_SECRET_DATA_FOR_CURRENT_LESSON_FAILURE:
		"GET_SECRET_DATA_FOR_CURRENT_LESSON_FAILURE",
	REMOVE_CURRENT_LESSON: "REMOVE_CURRENT_LESSON",
	GET_CURRENT_LESSON_START: "GET_CURRENT_LESSON_START",
	GET_CURRENT_LESSON_FAILURE: "GET_CURRENT_LESSON_FAILURE",
	GET_CURRENT_LESSON_SUCCESS: "GET_CURRENT_LESSON_SUCCESS",
	MODIFY_LESSONS: "MODIFY_LESSONS",
	GET_COMMENTS_START: "GET_COMMENTS_START",
	GET_COMMENTS_FAILURE: "GET_COMMENTS_FAILURE",
	GET_COMMENTS_SUCCESS: "GET_COMMENTS_SUCCESS",
	SEND_COMMENT_START: "SEND_COMMENT_START",
	SEND_COMMENT_FAILURE: "SEND_COMMENT_FAILURE",
	SEND_COMMENT_SUCCESS: "SEND_COMMENT_SUCCESS",
	DELETE_COMMENT_START: "DELETE_COMMENT_START",
	DELETE_COMMENT_FAILURE: "DELETE_COMMENT_FAILURE",
	DELETE_COMMENT_SUCCESS: "DELETE_COMMENT_SUCCESS",
	GET_MONDAY_LESSON_START: "GET_MONDAY_LESSON_START",
	GET_MONDAY_LESSON_FAILURE: "GET_MONDAY_LESSON_FAILURE",
	GET_MONDAY_LESSON_SUCCESS: "GET_MONDAY_LESSON_SUCCESS",
	REMOVE_MONDAY_LESSON: "REMOVE_MONDAY_LESSON",
};

export default LessonsActionTypes;
