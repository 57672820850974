import consentsActionTypes from "./consents.types";

const INITIAL_STATE = {
	PPandTandCConsent: false,
	RemindersConsent: false,
	MarketingConsent: false,
	underline: false,
};

const consentsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case consentsActionTypes.UPDATE_CONSENTS:
			return { ...state, ...action.payload };

		case consentsActionTypes.UPDATE_PPANDTANDCCONSENT:
			return { ...state, PPandTandCConsent: action.payload };

		case consentsActionTypes.UPDATE_REMINDERSCONSENT:
			return { ...state, RemindersConsent: action.payload };

		case consentsActionTypes.UPDATE_MARKETINGCONSENT:
			return { ...state, MarketingConsent: action.payload };

		case consentsActionTypes.RESET_CONSENTS:
			return {
				...state,
				PPandTandCConsent: false,
				RemindersConsent: false,
				MarketingConsent: false,
			};

		default:
			return state;
	}
};

export default consentsReducer;
