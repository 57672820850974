export const removeFormilla = () => {
	if (document.getElementById("formilla-frame")) {
		document.getElementById("formilla-frame").style.visibility = "hidden";
	} else {
		setTimeout(() => {
			removeFormilla();
		}, 1000);
	}
};

export const addFormilla = () => {
	if (document.getElementById("formilla-frame")) {
		document.getElementById("formilla-frame").style.visibility = "visible";
	} else {
		setTimeout(() => {
			addFormilla();
		}, 1000);
	}
};
