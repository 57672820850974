import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import lessonsReducer from "./lessons/lessons.reducer";
import loadingAnimationReducer from "./loading-animation/loading-animation.reducer";
import liveLessonReducer from "./live-lesson/live-lesson.reducer";
import currentUserReducer from "./current-user/current-user.reducer";
import subscriptionReducer from "./subscription/subscription.reducer";
import alertReducer from "./alert/alert.redux";
import flashcardsReducer from "./flashcards/flashcards.reducer";
import miniLessonsReducer from "./mini-lessons/mini-lessons.reducer";
import consentsReducer from "./consents/consents.reducer";
import metadataReducer from "./metadata/metadata.reducer";
import privateLessonsReducer from "./private-lessons/private-lessons.reducer";

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["lessons", "metadata"], //the list of reducers which I want to persist, in other words cache
};

const miniLessonsConfig = {
	key: "miniLessons",
	storage,
	whitelist: ["IDsIndex"],
};

const rootReducer = combineReducers({
	lessons: lessonsReducer,
	loadingAnimation: loadingAnimationReducer,
	liveLesson: liveLessonReducer,
	currentUser: currentUserReducer,
	subscription: subscriptionReducer,
	alert: alertReducer,
	flashcards: flashcardsReducer,
	miniLessons: persistReducer(miniLessonsConfig, miniLessonsReducer),
	consents: consentsReducer,
	metadata: metadataReducer,
	privateLessons: privateLessonsReducer,
});

export default persistReducer(persistConfig, rootReducer);
