import styled from "styled-components";

export const HorizontalContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 10px;
`;

export const Divider = styled.div`
	/* height: 150px; */
	height: 100%;
	width: 2px;
	background-color: gainsboro;
`;

export const Hour = styled.button`
	height: 30px;
	background-color: gray;
	border-radius: 8px;
	border: unset;
	color: white;
	font-weight: bold;
	margin: 3px;
	transition: background-color 0.5s linear;
	border: 3px solid transparent;
	padding-left: 4px;
	padding-right: 4px;

	&:hover {
		background-color: black;
	}
	${props =>
		props.selected
			? "border: 3px solid red;color:black;background-color:white; &:hover{background-color:unset}"
			: null}
`;

export const VerticalContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	min-width: 56px;
`;
