import { all, call } from "redux-saga/effects";

import { lessonsSagas } from "./lessons/lessons.sagas";
import { liveLessonSagas } from "./live-lesson/live-lesson.sagas";
import { subscriptionSagas } from "./subscription/subscription.sagas";
import { flashcardsSagas } from "./flashcards/flashcards.sagas";
import { miniLessonsSagas } from "./mini-lessons/mini-lessons.sagas";
import { consentsSagas } from "./consents/consents.sagas";

export default function* rootSaga() {
	yield all([
		call(lessonsSagas),
		call(liveLessonSagas),
		call(subscriptionSagas),
		call(flashcardsSagas),
		call(miniLessonsSagas),
		call(consentsSagas),
	]);
	//"all" calls all the sagas in the array in the same time
}

//call(fetchCollectionsStart) =is the same as= fetchCollectionsStart()
