import FlashcardsActionTypes from "./flashcards.types";
import flashcardsActionTypes from "./flashcards.types";

//
const INITIAL_STATE = {
	flashcards: [{ SwissGerman: "Loading", English: "Loading" }],
};

const flashcardsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case flashcardsActionTypes.GET_FLASHCARDS_SUCCESS:
			return { ...state, flashcards: action.payload };
		case FlashcardsActionTypes.RESET_FLASHCARDS:
			return {
				...state,
				flashcards: [{ SwissGerman: "Loading", English: "Loading" }],
			};
		default:
			return state;
	}
};

export default flashcardsReducer;
