import lessonsTypes from "./lessons.types";

export const addLessons = lessonsToAdd => ({
	type: lessonsTypes.ADD_LESSONS,
	payload: lessonsToAdd,
});

export const setCurrentLesson = currentLessonToSet => ({
	type: lessonsTypes.SET_CURRENT_LESSON,
	payload: currentLessonToSet,
});

export const getSecretDataForCurrentLessonStart = lessonID => ({
	type: lessonsTypes.GET_SECRET_DATA_FOR_CURRENT_LESSON_START,
	payload: lessonID,
});

export const getSecretDataForCurrentLessonSuccess = data => ({
	type: lessonsTypes.GET_SECRET_DATA_FOR_CURRENT_LESSON_SUCCESS,
	payload: data,
});

export const getSecretDataForCurrentLessonFailure = () => ({
	type: lessonsTypes.GET_SECRET_DATA_FOR_CURRENT_LESSON_FAILURE,
});

export const getCurrentLessonStart = lessonID => ({
	type: lessonsTypes.GET_CURRENT_LESSON_START,
	payload: lessonID,
});

export const getCurrentLessonSuccess = data => ({
	type: lessonsTypes.GET_CURRENT_LESSON_SUCCESS,
	payload: data,
});

export const getCurrentLessonFailure = () => ({
	type: lessonsTypes.GET_CURRENT_LESSON_FAILURE,
});

export const modifyLessons = lessons => ({
	type: lessonsTypes.MODIFY_LESSONS,
	payload: lessons,
});

export const getCommentsStart = videoID => ({
	type: lessonsTypes.GET_COMMENTS_START,
	payload: videoID,
});

export const getCommentFailure = () => ({
	type: lessonsTypes.GET_COMMENTS_FAILURE,
});

export const getCommentsSuccess = comments => ({
	type: lessonsTypes.GET_COMMENTS_SUCCESS,
	payload: comments,
});

export const sendCommentsStart = comment => ({
	type: lessonsTypes.SEND_COMMENT_START,
	payload: comment,
});

export const sendCommentFailure = () => ({
	type: lessonsTypes.SEND_COMMENT_FAILURE,
});

export const sendCommentSuccess = () => ({
	type: lessonsTypes.SEND_COMMENT_SUCCESS,
});

export const deleteCommentStart = comment => ({
	type: lessonsTypes.DELETE_COMMENT_START,
	payload: comment,
});

export const deleteCommentSuccess = () => ({
	type: lessonsTypes.DELETE_COMMENT_SUCCESS,
});

export const deleteCommentFailure = () => ({
	type: lessonsTypes.DELETE_COMMENT_FAILURE,
});

export const removeCurrentLesson = () => ({
	type: lessonsTypes.REMOVE_CURRENT_LESSON,
});

export const getMondayLessonStart = MondayLessonID => ({
	type: lessonsTypes.GET_MONDAY_LESSON_START,
	payload: MondayLessonID,
});

export const getMondayLessonSuccess = MondayLesson => ({
	type: lessonsTypes.GET_MONDAY_LESSON_SUCCESS,
	payload: MondayLesson,
});

export const getMondayLessonFailure = () => ({
	type: lessonsTypes.GET_MONDAY_LESSON_FAILURE,
});

export const removeMondayLesson = () => ({
	type: lessonsTypes.REMOVE_MONDAY_LESSON,
});
