import styled, { css } from "styled-components";

import ReactHlsPlayer from "react-hls-player";

const sharedVideoPlayerStyling = css`
	width: 100%;
	box-shadow: 0 0 5px gray;
	border-radius: 10px;
	transition-duration: ${props => props.transitionduration}s;
	background-color: black;
`;

export const HlsVideoPlayer = styled(ReactHlsPlayer)`
	${sharedVideoPlayerStyling}
`;

export const RegularVideoPlayer = styled.video`
	${sharedVideoPlayerStyling}
`;
