import React from "react";
import { RedText } from "./privacy-policy-text.button.styles";

const PrivacyPolicy = () => {
	const PPClicked = () => {
		window.open("/privacy-policy");
	};

	return <RedText onClick={PPClicked}>Privacy Policy</RedText>;
};

export default PrivacyPolicy;
