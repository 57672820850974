import PrivateLessonActionTypes from "./private-lessons.types";

const INITIAL_STATE = {};

const privateLessonsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PrivateLessonActionTypes.SET_SELECTED_PRIVATE_LESSON:
			return { ...state, selected: action.payload };

		default:
			return state;
	}
};

export default privateLessonsReducer;
