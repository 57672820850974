export const selectMiniLessons = state => state.miniLessons;

export const selectCurrentMiniLessonID = state =>
	selectMiniLessons(state).current.ID;

export const selectNewestMiniLessonID = state =>
	selectMiniLessons(state).newestMiniLesson.DocID;

export const selectNewestMiniLessonVideoThumbnailURL = state =>
	selectMiniLessons(state).newestMiniLesson.VideoThumbnailURL;

export const selectCurrentMiniLessonVideoHLSurl = state =>
	selectMiniLessons(state).current.VideoHLSurl;

export const selectCurrentMiniLessonText = state =>
	selectMiniLessons(state).current.Text;
