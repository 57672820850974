import AlertActionTypes from "./alert.types";

export const showAlertAction = alert => ({
	type: AlertActionTypes.SHOW_ALERT,
	payload: alert,
});

export const hideAlertAction = () => ({
	type: AlertActionTypes.HIDE_ALERT,
});
