import FlashcardsActionTypes from "./flashcards.types";

export const getFlashcardsStart = payload => ({
	type: FlashcardsActionTypes.GET_FLASHCARDS_START,
	payload: payload,
});

export const getFlashcardsSuccess = payload => ({
	type: FlashcardsActionTypes.GET_FLASHCARDS_SUCCESS,
	payload: payload,
});

export const getFlashcardsFailure = () => ({
	type: FlashcardsActionTypes.GET_FLASHCARDS_FAILURE,
});

export const resetFlashcards_Action = () => ({
	type: FlashcardsActionTypes.RESET_FLASHCARDS,
});
