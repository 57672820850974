import currentUserActionTypes from "./current-user.types";

export const addUser = user => ({
	type: currentUserActionTypes.ADD_USER,
	payload: user,
});

export const removeUser = () => ({ type: currentUserActionTypes.REMOVE_USER });

export const startSignUpFlow = payload => ({
	type: currentUserActionTypes.START_SIGN_UP_FLOW,
	payload,
});

export const endSignUpFlow = () => ({
	type: currentUserActionTypes.END_SIGN_UP_FLOW,
});

export const setUploadConsentWhenAuthenticatedSuccessfully = payload => ({
	type: currentUserActionTypes.SET_UPLOAD_CONSENTS_WHEN_AUTHENTICATED_SUCCESSFULLY,
	payload,
});
